/* eslint-disable import/prefer-default-export */
export const optionsLoyalty = (t) => [
    {
        aclCode: 'acl_loyalty_header',
        key: 'loyalty',
        label: t('menu:Loyalty'),
        notInAcl: false,
        children: [
            {
                aclCode: 'acl_loyalty_customer',
                key: 'loyalty-customer',
                label: t('menu:Loyalty_Customers'),
                url: '/loyalty/customers',
                notInAcl: false,
            },
            {
                aclCode: 'acl_loyalty_transaction',
                key: 'loyalty-transaction',
                label: t('menu:Loyalty_Transactions'),
                url: '/loyalty/transactions',
                notInAcl: false,
            },
            {
                aclCode: 'acl_loyalty_configuration',
                key: 'loyalty-configuration',
                label: t('menu:Loyalty_Configuration'),
                url: '/loyalty/configuration',
                notInAcl: false,
            },
            {
                aclCode: 'acl_loyalty_earning_rule',
                key: 'loyalty-earning-rule-point',
                label: t('menu:Points_Earning_Rule'),
                url: '/loyalty/earningrulepoint',
            },
            {
                aclCode: 'acl_loyalty_header',
                key: 'manage-tier',
                label: t('menu:Manage_Tier'),
                url: '/loyalty/managetier',
            },
        ],
    },
];
